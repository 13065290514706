/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import NextImage from '@base/Image';

type StyledImageProps = {
  $noCrop: boolean;
};

const StyledImage = styled(NextImage)<StyledImageProps>`
  height: 100%;
  width: 100%;
  transform: scale(${({ $noCrop }) => ($noCrop && '1') || '1.02'});
  background-color: var(--color-divider);
  object-fit: cover;
  @media only screen and (max-width: 47.95em) {
    transform: scale(1);
  }
`;

type StyledDivProps = {
  $orientation: string;
  $borderRadius: string;
};

const StyledDiv = styled.div<StyledDivProps>`
  height: ${({ $orientation }) => $orientation === 'vertical' && '152px'};
  width: 272px;
  object-fit: cover;
  position: relative;
  background-color: var(--color-divider);
  border-radius: ${({ $borderRadius }) => $borderRadius};
  overflow: hidden;
`;

type ThumbnailProps = {
  imgUrl: string;
  orientation?: 'horizontal' | 'vertical';
  className?: string;
  visibleByDefault?: boolean;
  onClick?: (...args: any[]) => any;
  children?: ReactNode;
  noCrop?: boolean;
  borderRadius?: string;
  isUnoptimized?: boolean;
  priority?: boolean;
  alt?: string;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
  width?: number;
  height?: number;
  sizes?: string;
};
const Thumbnail: FC<ThumbnailProps> = ({
  imgUrl,
  orientation = 'vertical',
  className = '',
  children = null,
  visibleByDefault = false,
  onClick = () => {},
  noCrop = false,
  borderRadius = '0px',
  alt = 'Thumbnail',
  isUnoptimized,
  priority = false,
  layout = 'fill',
  width = 272,
  height = 152,
  sizes,
  ...props
}) => {
  return (
    <StyledDiv
      $orientation={orientation}
      className={`${className} batch-thumbnail`}
      onClick={onClick}
      $borderRadius={borderRadius}
      {...props}
    >
      <StyledImage
        priority={priority}
        visibleByDefault={visibleByDefault}
        src={imgUrl}
        alt={alt}
        orientation={orientation}
        $noCrop={noCrop}
        decoding="async"
        layout={layout}
        unoptimized={isUnoptimized}
        width={width}
        height={height}
        sizes={sizes}
      />
      {children}
    </StyledDiv>
  );
};

Thumbnail.defaultProps = {
  isUnoptimized: false
};

export default Thumbnail;
